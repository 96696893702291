import React from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useToken from './features/authentication/hooks/useToken';

import Layout from "./pages/layout";

import Login from './pages/account/login';
import Signup from './pages/account/signup';
import ForgotPassword from './pages/account/forgotPassword';
import ResetPassword from './pages/account/resetpassword';
import Home from "./pages/home/home";
import Templates from "./pages/template/templates";
import TemplateDetail from "./pages/template/templateDetail";
import TemplateEdit from "./pages/template/templateEdit";
import Logs from "./pages/home/logs";
import Users from "./pages/home/users";
import NoPage from "./pages/nopage";
import EnvironementDetail from "./pages/environment/environmentDetail";

function App() {
  const { token, setToken, clearToken } = useToken();

  if (window.location.pathname == '/account/forgotPassword') { return <ForgotPassword /> }
  if (window.location.pathname == '/account/resetpassword') { return <ResetPassword /> }
  if (window.location.pathname == '/register') { return <Signup /> }

  if (!token) {
    return <Login setToken={setToken} />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout clearToken={clearToken} />}>
          <Route index element={<Home />} />
          <Route path="users" element={<Users />} />
          <Route path="logs" element={<Logs />} />
          <Route path="environments/:id" element={ <EnvironementDetail /> } />
          <Route path="templates" element={<Templates />} />
          <Route path="templates/:id/detail" element={ <TemplateDetail /> } />
          <Route path="templates/:id/edit" element={ <TemplateEdit /> } />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;